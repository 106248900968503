<template>
  <div>
    <app-form-table-printing type="original" />
    <app-form-table-printing type="copy" />
  </div>
</template>

<script>
import AppFormTablePrinting from '../components/FormTablePrinting.vue';
export default {
  components: {
    AppFormTablePrinting
  }
}
</script>
