import Vue from 'vue';
import App from './App.vue';
import VueCompositionAPI from '@vue/composition-api';
import axios from 'axios';
import { TablePlugin, FormInputPlugin, LayoutPlugin, ButtonPlugin, PaginationPlugin, TooltipPlugin, ModalPlugin, FormDatepickerPlugin, FormSelectPlugin, FormGroupPlugin } from 'bootstrap-vue';
import VueSweetalert2 from 'vue-sweetalert2';
import VueRouter from 'vue-router';
import Printing from './components/Printing.vue';
import Home from './components/List.vue';



// If you don't need the styles, do not connect

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import "vue-multiselect/dist/vue-multiselect.min.css";


Vue.use(VueRouter);
// Optionally install the BootstrapVue icon components plugin
Vue.use(TablePlugin);
Vue.use(FormInputPlugin);
Vue.use(LayoutPlugin);
Vue.use(ButtonPlugin);
Vue.use(PaginationPlugin);
Vue.use(TooltipPlugin);
Vue.use(ModalPlugin);
Vue.use(FormDatepickerPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormGroupPlugin);
Vue.use(VueSweetalert2);
Vue.use(VueCompositionAPI);
Vue.filter('accounting', function (value, decimal) {
  const countryCode = 'EN-ID';

  const option = {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  };

  if (decimal !== undefined) { option.maximumFractionDigits = decimal; }

  const formatter = new Intl.NumberFormat(countryCode, option);

  return formatter.format(value);
});

Vue.config.productionTip = false;

Vue.use({
  install (Vue) {
    Vue.prototype.$axios = axios.create({
      // baseURL: 'http://megatrend-extra.test/api/'
      baseURL: 'https://extra.megatrend.xyz/api/'
    });
  }
});

const routes = [
  { path: '/', component: Home },
  { path: '/print/:id', component: Printing },
];

const router = new VueRouter({
  mode: 'history',
  routes // short for `routes: routes`
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');

