<template>
  <div style="display:inline">
    <b-button
      ref="icon-button"
      v-b-tooltip="tooltipConfig"
      :variant="selected.variant"
      :size="size"
      @click="$emit('click')"
    >
      <i :class="selected.icon" />
    </b-button>
  </div>
</template>
<script>
import "@fortawesome/fontawesome-free/css/solid.css";
import "@fortawesome/fontawesome-free/css/regular.css";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/all.css";
export default {
  name: 'IconButton',
  props: {
    type: {
      type: String,
      default: 'Edit'
    },
    size: {
      type: String,
      default: 'sm'
    },
    tooltipTitle: {
      type: String,
      default: ''
    },
    toolTiptrigger: {
      type: String,
      default: 'hover'
    },
    tooltipPlacement: {
      type: String,
      default: 'right'
    }
  },
  data () {
    return {
      types: [
        { name: 'edit', icon: 'fa fa-edit', variant: 'warning', title: 'Edit' },
        { name: 'remove', icon: 'fas fa-minus-circle', variant: 'danger', title: 'Hapus' },
        { name: 'print', icon: 'fa fa-print', variant: 'outline-primary', title: 'Cetak' },
        { name: 'check', icon: 'fas fa-check-double', variant: 'outline-success', title: 'Check' },
        { name: 'finalize', icon: 'fas fa-tasks', variant: 'success', title: 'Finalize' },
        { name: 'change', icon: 'fas fa-exchange-alt', variant: 'outline-primary', title: 'Change' },
        { name: 'view', icon: 'fas fa-eye', variant: 'outline-primary', title: 'Detail' }
      ]
    };
  },
  computed: {
    selected () {
      const base = { icon: 'fa fa-edit', variant: 'success', title: 'Edit' };
      const found = this.types.find(type => type.name === this.type);

      return found || base;
    },
    tooltipConfig () {
      return {
        title: this.tooltipTitle === '' ? this.selected.title : this.tooltipTitle,
        trigger: this.tooltipTrigger,
        placement: this.tooltipPlacement
      };
    }
  }
};
</script>
