<template>
  <div>
    <div class="text-center">
      <h1 style="position:relative">
        {{ title }}
        <b-button
          class="centered-btn"
          variant="outline-primary"
          @click="$emit('primary-button-clicked')"
        >
          <slot name="primaryButton">
            {{ primaryButtonLabel }}
          </slot>
        </b-button>
      </h1>
    </div>
    <hr
      id="bottomLine"
      v-if="bottomLine"
      class="mt-2 mb-4"
    >
  </div>
</template>
<script>
export default {
  name: 'Header',
  props: {
    title: {
      type: String,
      default: ''
    },
    primaryButtonLabel: {
      type: String,
      default: 'Create +'
    },
    bottomLine: {
      type: Boolean,
      default: true
    },
  },
};
</script>
<style scoped>
.centered-btn {
  position: absolute;
  right: 0;
  height: 40px;
  top: 50%;
  margin-top: -20px;
}
</style>

