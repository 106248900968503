import useRequest from './request';
import { ref } from '@vue/composition-api';
export default function usePagination (context) {
  let isLoading = ref(false);
  const has = (obj, key) => {
    return key.split('.').every(function (k) {
      if (typeof obj !== 'object' || obj === null || !(k in obj)) {
        return false;
      }

      obj = obj[k];

      return true;
    });
  };
  const { getUrl, getQuery } = useRequest();


  function get ({ url, query, config, table, attachingRow = true, queryString = '' } = {}) {

    if (url == null && typeof url !== 'string') {
      return null;
    }

    if (config == null &&
      typeof config !== 'object' &&
      !Array.isArray(config)
    ) {
      return null;
    }

    if (query !== undefined && query !== null && typeof query === 'object') {
      queryString = queryString.concat(getQuery(query));
    }

    if (table && table.filters) {
      queryString = queryString.concat(getQuery({ filters: table.filters }));
    }

    let perPage = 2;

    let currentPage = 1;

    if (config.perPage &&
      typeof config.perPage === 'number'
    ) {
      perPage = config.perPage;
    }

    if (config.currentPage &&
      typeof config.currentPage === 'number'
    ) {
      currentPage = config.currentPage;
    }

    queryString = queryString.concat(`&perPage=${perPage}`);

    queryString = queryString.concat(`&page=${currentPage}`);

    url = getUrl({ url }).concat(queryString);


    return new Promise((resolve, reject) => {
      try {
        context.root.$axios.get(url).then((response) => {
          setPaginationConfig(config, response.data.meta);

          console.log(response);

          const data = response.data.data;

          if (data != null && attachingRow === true) {
            attachRow({ items: data, perPage: config.perPage, currentPage: config.currentPage });
          }

          if (table && table.items) { table.items = data; }

          return resolve(data);
        });
      } catch (error) {
        const title = 'Pagination Failed';

        context.root.$swal(title, error.message, 'error');

        reject(error);
      }
    });
  }

  function setPaginationConfig (config, meta) {
    if (meta === null || meta === undefined) { return; }

    try {
      if (has(config, 'totalRows')) {
        config.totalRows = meta.total;
      }

      if (has(config, 'lastPage')) {
        config.lastPage = meta.last_page;
      }

      if (has(config, 'from')) {
        config.from = meta.from;
      }

      if (has(config, 'to')) {
        config.to = meta.to;
      }
    } catch (error) {
      const title = 'Set Pagination Value Failed';

      context.root.$swal(title, error.message, 'error');
    }
  }

  function attachRow ({ items, perPage = 1, currentPage = 1 }) {
    if (!Array.isArray(items)) { return; }

    if (items.length < 1) { return items; }

    return items.map((obj, index) => {
      const no = (currentPage - 1) * perPage + (index + 1);

      obj.row = no;

      return obj;
    });
  }

  function getFilterOptions (filters) {
    if (!Array.isArray(filters) || filters.length === 0) { return; }

    return new Promise((resolve, reject) => {
      if (!Array.isArray(filters) &&
        filters.length === 0
      ) {
        return;
      }

      const promises = [];

      filters.forEach((filter) => {
        if (has(filter, 'url')) {
          promises.push(context.root.$axios.get(filter.url).then((res) => {
            let newOptions = res.data;

            if (filter.type === 'select') {
              newOptions = newOptions.map((el) => {
                const obj = el;

                obj.value = el.id;

                obj.text = el.name;

                return obj;
                // return {
                //   value: el.id,
                //   text: el.name
                // };
              });
            }

            filter.options = [
              ...filter.options,
              ...newOptions
            ];
          }).catch((error) => {
            const errorTitle = 'Push Filter Options Failed';

            context.root.$swal(errorTitle, error.message, 'error');

            return resolve(error);
          }));
        }
      });

      try {
        return resolve((Promise.all(promises)));
      } catch (error) {
        const errorTitle = 'Request Filter Options Failed';

        context.root.$swal(errorTitle, error.message, 'error');

        return reject(error);
      }
    });
  }
  async function changePage ({
    page,
    url,
    query,
    table,
    config,
    attachRow = true
  } = {}) {
    try {
      if (typeof page !== 'number') { throw new TypeError('Page is not Number'); }

      config.currentPage = page;

      const records = await get({ url, config, query, table, attachRow });

      return records;
    } catch (error) {
      const errorTitle = 'Pagination Failed';

      context.root.$swal(errorTitle, error, 'error');
    }
  }
  async function changePerPage ({
    perPage,
    url,
    query,
    table,
    config,
    attachRow = true
  } = {}) {
    try {
      if (typeof perPage !== 'number') { throw new TypeError('PerPage is not Number'); }

      config.perPage = perPage;



      const records = await get({ url, query, table, config, attachRow });

      return records;
    } catch (error) {
      const errorTitle = 'Pagination Failed';

      return context.root.$swal(errorTitle, error, 'error');
    }
  }
  function setFilterValue ({
    value,
    index,
    filters,
    table,
    url,
    query,
    config
  }) {
    const filter = filters[index];

    filter.value = value;

    if (filter.type === 'text') {
      if (window.event.key !== 'Enter') { return; }
    }

    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        config.currentPage = 1;


        try {
          const items = await get({ url, query, table, config });

          resolve(items);
        } catch (error) {
          const errorTitle = 'Filtering Pagination Failed';

          context.root.$swal(errorTitle, error, 'error');

          reject(error);
        }
      }, 100);
    });
  }
  async function load ({ filters, api, config, table } = {}) {

    getFilterOptions(filters);

    await get({
      ...api,
      config,
      table
    });

  }

  return {
    get,
    setPaginationConfig,
    attachRow,
    getFilterOptions,
    changePage,
    changePerPage,
    setFilterValue,
    load,
    isLoading
  };
}

