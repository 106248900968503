<template>
  <div id="app">
    <b-container>
      <app-header
        :title="title"
        @primary-button-clicked="create"
      ></app-header>
      <app-pagination-table
        :items="table.items"
        :fields="table.fields"
        :perPage="table.pagination.perPage"
        :pageOptions="table.pagination.pageOptions"
        :totalRows="table.pagination.totalRows"
        :currentPage="table.pagination.currentPage"
        @per-page-changed="perPage=>changePerPage(perPage)"
        @page-changed="page=>changePage(page)"
      >
        <template #searchBar>
          <b-row>
            <b-col
              cols="6"
              class="my-1"
            >
              <b-form-input
                v-model="api.main.query.filters[0].value"
                type="search"
                placeholder="Cari..."
                @keyup.enter="reloadItems()"
              />
            </b-col>
            <b-col
              cols="6"
              class="my-1 d-flex"
            >
              <b-form-select
                class="align-self-center"
                v-model="api.main.query.filters[1].value"
                :options="table.filters.receivedDate.options"
                @change="reloadItems()"
              />
            </b-col>
          </b-row>
        </template>
        <template #cell(countSerial)="row">
          {{ getCountSerialByDo(row.item)}}
        </template>
        <template #cell(actions)="row">
          <app-icon-button
            type="edit"
            style="margin-right:5px;"
            @click="edit(row.item)"
          ></app-icon-button>
          <app-icon-button
            v-if="row.item.receivedDate === null"
            type="check"
            tooltipTitle="Receive"
            style="margin-right:5px;"
            @click="showReceiveModal(row.item)"
          ></app-icon-button>
          <app-icon-button
            type="print"
            style="margin-right:5px;"
            @click="print(row.item)"
          ></app-icon-button>
          <app-icon-button
            type="remove"
            style="margin-right:5px;"
            @click="remove(row.item, row.index)"
          ></app-icon-button>
        </template>
      </app-pagination-table>
    </b-container>

    <b-modal
      :id="receive.modal.id"
      size="xl"
      :title="`Receive #${deliveryOrder.value ? deliveryOrder.value.no :''}`"
      footer-class='custom-modal-footer'
      ok-only
      ok-title="Submit"
      :no-enforce-focus="true"
      @ok="submitReceive"
    >
      <b-row>
        <b-col cols="6">
          <div>
            <b>No : </b>
            {{deliveryOrder.value ? deliveryOrder.value.no : ''}}
          </div>
          <div>
            <b>Nama : </b>
            {{deliveryOrder.value ? deliveryOrder.value.customer.name : ''}}
          </div>
          <div>
            <b>Tgl DO Dibuat: </b>
            {{deliveryOrder.value ? deliveryOrder.value.shipmentDate : ''}}
          </div>
        </b-col>
        <b-col cols="6">
          <div><b>Tanggal Terkirim</b></div>
          <b-form-datepicker
            id="receiveDatePicker"
            v-model="receive.value"
            :readonly="false"
            class="mb-2"
            :min="receive.min"
            :max="receive.max"
            :value-as-date="true"
          ></b-form-datepicker>
        </b-col>
      </b-row>
      <b-row>
        <b-table
          :items="deliveryOrder.value ? deliveryOrder.value.details : []"
          :fields="receive.table.invoiceFields"
          bordered
          responsive
          class="mt-4 text-center"
        >
          <template #cell(index)="row">
            {{row.index + 1}}
          </template>
          <template #cell(countSerial)="row">
            {{row.item.salesTransaction.packingSerial.details.length}}
          </template>
          <template #cell(detail)="row">
            <b-button
              size="sm"
              @click="row.toggleDetails"
            >
              {{ row.detailsShowing ? 'Hide' : 'Show'}}
            </b-button>
          </template>
          <template #row-details="rowDetail">
            <b-table
              :items="rowDetail.item.salesTransaction.details"
              :fields="receive.table.invoiceDetailFields"
              bordered
              responsive
              class="text-center"
            >
              <template #cell(index)="row">
                {{row.index + 1}}
              </template>
              <template #cell(qty)="row">
                <div>{{row.item.unitQty}} {{row.item.unit.code}} ({{row.item.qty}} {{row.item.product.baseUnit.code}})</div>
              </template>
            </b-table>
          </template>
        </b-table>
      </b-row>
    </b-modal>
    <b-modal
      :id="deliveryOrder.modal.id"
      size="xl"
      :title="deliveryOrder.modal.title"
      footer-class='custom-modal-footer'
      :no-enforce-focus="true"
      @hide="reset"
    >
      <template #modal-footer>
        <div>
          <b-button
            v-if="deliveryOrder.selectedInvoices.length > 0"
            variant="primary"
            @click="submit"
          >{{deliveryOrder.edit ? 'Update' : 'Submit'}}</b-button>
        </div>
      </template>
      <!-- <div class="border-1 border-danger">
        <b-form-group
          id="fieldset-customer"
          label-cols="4"
          content-cols="8"
          label="Nama Customer"
          label-for="input-customer"
          class="d-flex align-items-center"
        >
          <b-form-select
            v-model="customer.value"
            :options="customer.items"
            id="input-customer"
          ></b-form-select>
        </b-form-group>
      </div> -->
      <b-row class="mb-2">
        <b-col
          cols="4"
          md="2"
        ><b>Nama Customer :</b></b-col>
        <b-col cols="8">
          <multiselect
            :value="customer.value"
            :options="customer.items"
            :searchable="true"
            :allow-empty="false"
            id="input-customer"
            :custom-label="(val) => val.name ? val.name : 'label'"
            @input="setCustomer"
            :disabled="deliveryOrder.edit"
          />
          <!-- <b-form-select
            :value="customer.value"
            :options="customer.items"
            id="input-customer"
            @change="setCustomer"
            :disabled="deliveryOrder.edit"
          ></b-form-select> -->
        </b-col>
      </b-row>
      <b-row v-if="customer.value !== null">
        <b-col
          cols="4"
          md="2"
        ><b>Total Terpilih :</b></b-col>
        <b-col cols="8">
          {{deliveryOrder.selectedInvoices.length}} Invoice
        </b-col>
      </b-row>
      <b-table
        v-if="customer.value !== null"
        :items="customer.value === null? [] : getCustomer(customer.value).salesTransactions"
        :fields="deliveryOrder.table.invoiceFields"
        bordered
        responsive
        select-mode="multi"
        selectable
        ref="selectableTable"
        class="mt-4 text-center"
        @row-selected="onInvoiceSelected"
      >
        <template #cell(index)="row">
          {{row.index + 1}}
        </template>
        <template #cell(selected)="{ rowSelected }">
          <template v-if="rowSelected">
            <span aria-hidden="true">&check;</span>
            <span class="sr-only">Selected</span>
          </template>
          <template v-else>
            <span aria-hidden="true">&nbsp;</span>
            <span class="sr-only">Not selected</span>
          </template>
        </template>
        <template #cell(countSerial)="row">
          {{row.item.packingSerial.details.length}}
        </template>
        <template #cell(detail)="row">
          <b-button
            size="sm"
            @click="row.toggleDetails"
          >
            {{ row.detailsShowing ? 'Hide' : 'Show'}}
          </b-button>
        </template>
        <template #row-details="rowDetail">
          <b-table
            :items="rowDetail.item.details"
            :fields="deliveryOrder.table.invoiceDetailFields"
            bordered
            responsive
            class="text-center"
          >
            <template #cell(index)="row">
              {{row.index + 1}}
            </template>
            <template #cell(qty)="row">
              <div>{{row.item.unitQty}} {{row.item.unit.code}} ({{row.item.qty}} {{row.item.product.baseUnit.code}})</div>
            </template>
          </b-table>
        </template>
      </b-table>
    </b-modal>
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#0000FF"
      tabindex="-1"
    />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Multiselect from 'vue-multiselect';
import { ref, reactive } from '@vue/composition-api';
import useRequest from '../hooks/request';
import usePagination from '../hooks/pagination';
import AppHeader from '../components/Header.vue';
import AppIconButton from '../components/IconButton.vue';
import AppPaginationTable from '../components/PaginationTable.vue';


export default {
  name: 'App',
  components: {
    AppHeader,
    AppPaginationTable,
    Loading,
    AppIconButton,
    Multiselect,
  },
  setup (props, context) {
    const title = ref('Daftar Delivery Order');

    const table = reactive({
      fields: [
        { key: 'row', label: '#' },
        { key: 'no', label: 'No.' },
        { key: 'customer.name', label: 'Name' },
        { key: 'type', label: 'Jenis' },
        { key: 'countSerial', label: 'Total Dus' },
        { key: 'shipmentDate', label: 'Shipping Date' },
        { key: 'receivedDate', label: 'Received Date' },
        { key: 'actions', label: 'Actions' },
      ],
      items: [],
      pagination: {
        perPage: 50,
        pageOptions: [
          25,
          50,
          100
        ],
        totalRows: 0,
        currentPage: 1
      },
      filters: {
        receivedDate: {
          options: [
            { value: null, text: 'Semua' },
            { value: false, text: 'Belum Receive' },
            { value: true, text: 'Sudah Receive' },
          ]
        }
      }
    });

    const { getUrl } = useRequest();
    const { get, changePage, changePerPage, isLoading, load } = usePagination(context);

    const api = reactive({
      main: {
        url: 'delivery-order',
        query: {
          sort: '-no',
          includes: [
            'details.salesTransaction.packingSerial.details',
            'details.salesTransaction.details.product.unit',
            'details.salesTransaction.details.unit',
            'customer.detail'],
          appends: [],
          fields: [],
          filters: [
            { name: 'search', value: '' },
            { name: 'onlyHasReceivedDate', value: null },
          ],
          others: []
        }
      },
      receive: {
        url: 'delivery-order/submit-receive-date'
      },
      customer: {
        url: 'customer',
        query: {
          sort: 'name',
          includes: [],
          appends: [],
          fields: [],
          filters: [
            { name: 'withSalesTransactionsForDO', value: true }
          ],
          others: []
        }
      }
    });

    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    const minDate = new Date(today);
    minDate.setMonth(minDate.getMonth() - 45);
    minDate.setDate(1);

    const receive = reactive({
      modal: {
        id: 'receiveModal'
      },
      value: today,
      min: minDate,
      max: today,
      table: {
        invoiceFields: [
          { key: 'index', label: '#' },
          { key: 'salesTransaction.no', label: 'No. Invoice' },
          { key: 'countSerial', label: 'Total Serial' },
          { key: 'detail', label: 'Detail' },
        ],
        invoiceDetailFields: [
          { key: 'index', label: '#' },
          { key: 'product.code', label: 'Kode' },
          { key: 'product.name', label: 'Nama' },
          { key: 'qty', label: 'Qty' },
        ],
      }
    });

    const customer = reactive({
      item: [],
      // items: [{ id: null, name: 'Harap Pilih Salah Satu' }],
      value: null,
    });

    const deliveryOrder = reactive({
      value: null,
      edit: false,
      selectedInvoices: [],
      modal: {
        id: 'deliveryOrderModal',
        title: 'Create Delivery Order',
        okButtonTitle: 'Submit',
      },
      table: {
        invoiceFields: [
          { key: 'selected', label: 'Pilih' },
          { key: 'index', label: '#' },
          { key: 'no', label: 'No. Invoice' },
          { key: 'countSerial', label: 'Total Serial' },
          { key: 'detail', label: 'Detail' },
        ],
        invoiceDetailFields: [
          { key: 'index', label: '#' },
          { key: 'product.code', label: 'Kode' },
          { key: 'product.name', label: 'Nama' },
          { key: 'qty', label: 'Qty' },
        ],
      }
    });

    const headers = reactive({
      no: { label: 'Nomor', value: 'DO-192381321' },
      customer: { label: 'Kepada', value: 'Junior Online Store' },
      address: { label: 'Alamat', value: 'Jl. Merdeka' },
      date: { label: 'Tanggal', value: '12/05/2021' },
      type: { label: 'Type', value: 'Serial' },
    });

    return {
      isLoading,
      title,
      table,
      api,
      get,
      pagination_changePage: changePage,
      pagination_changePerPage: changePerPage,
      getUrl,
      load,
      deliveryOrder,
      receive,
      customer,
      headers
    };
  },
  async mounted () {
    this.isLoading = true;

    await this.load({
      config: this.table.pagination,
      table: this.table,
      api: this.api.main
    });

    this.isLoading = false;

    console.log(this.table.items);

    this.setLocalStorage();

  },
  methods: {
    setLocalStorage () {
      window.localStorage.setItem('do', JSON.stringify(this.table.items));
    },
    async reloadItems () {
      this.isLoading = true;
      await this.get({
        config: this.table.pagination,
        table: this.table,
        ...this.api.main
      });

      this.setLocalStorage();

      this.isLoading = false;
    },
    async changePage (page) {
      this.isLoading = true;
      await this.pagination_changePage({ page, ...this.api.main, table: this.table, config: this.table.pagination });
      this.setLocalStorage();
      this.isLoading = false;
    },
    async changePerPage (perPage) {
      this.isLoading = true;
      await this.pagination_changePerPage({ perPage, ...this.api.main, table: this.table, config: this.table.pagination });
      this.setLocalStorage();
      this.isLoading = false;
    },
    showModal (id) {
      this.$bvModal.show(id);
    },
    showReceiveModal (item) {
      this.deliveryOrder.value = item;
      // for (const prop in item) {
      //   this.$set(this.deliveryOrder.value, prop, item[prop]);
      // }
      this.$bvModal.show(this.receive.modal.id);
    },
    async submitReceive (event) {
      event.preventDefault();
      this.isLoading = true;

      try {
        const response = await this.$axios.post(`${this.api.receive.url}/${this.deliveryOrder.value.id}`, {
          receivedDate: new Date(this.receive.value).toLocaleString()
        });


        if (response.status === 200 || response.status === 201) {
          this.deliveryOrder.value.receivedDate = response.data.data.receivedDate;

          this.isLoading = false;
          await this.$swal('Sukses', `${this.deliveryOrder.value.no} Berhasil Di-Receive`, 'success');

        }
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      } finally {
        this.$bvModal.hide(this.receive.modal.id);
      }

    },
    getCustomer (customer) {
      return this.customer.items.find(item => item.id === customer.id);
    },
    async create () {
      this.deliveryOrder.edit = false;
      const url = this.getUrl(this.api.customer);
      this.isLoading = true;
      try {
        const response = await this.$axios.get(url);

        if (response.status === 200 || response.status === 201) {
          this.customer.items = [
            // { id: null, name: 'Harap Pilih Salah Satu' },
            ...response.data.data.map(item => {
              return {
                id: item.id,
                name: item.name,
                salesTransactions: item.salesTransactions.map(st => {
                  return {
                    ...st,
                    rowSelected: false
                  };
                })
              };
            })
          ];
        }

        this.$bvModal.show(this.deliveryOrder.modal.id);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }

    },
    async submit () {
      if (this.deliveryOrder.edit === true) {
        return this.update();
      }
      this.isLoading = true;
      const url = this.api.main.url;
      try {
        const response = await this.$axios.post(`${url}`, {
          salesTransactions: this.deliveryOrder.selectedInvoices.map(invoice => invoice.id)
        });

        const newDeliveryOrders = response.data.data;

        if (response.status === 200 || response.status === 201) {
          newDeliveryOrders.forEach(order => {
            this.table.items.unshift(order);
          });
          this.table.items.forEach((item, index) => {
            item.row = index + 1;
          });

          this.isLoading = false;

          let label = '';

          if (newDeliveryOrders.length > 1) {
            label = '2 Delivery Order';
          } else {
            label = newDeliveryOrders[0].no;
          }

          await this.$swal('Sukses', `${label} Berhasil Di-buat`, 'success');
          this.setLocalStorage();
        }


      } catch (error) {
        this.isLoading = false;
        console.log(error.response);
        this.$swal('Gagal', error.response.data.message, 'error');
      } finally {
        this.isLoading = false;

        this.$bvModal.hide(this.deliveryOrder.modal.id);
        this.reset();
      }

    },
    async update () {
      this.isLoading = true;
      const url = this.api.main.url;
      try {
        const response = await this.$axios.patch(`${url}/${this.deliveryOrder.value.id}`, {
          salesTransactions: this.deliveryOrder.selectedInvoices.map(invoice => invoice.id)
        });

        const updatedOrder = { ...response.data.data };

        if (response.status === 200 || response.status === 201) {
          this.isLoading = false;
          const found = this.table.items.find(item => item.id === updatedOrder.id);

          if (found) {
            found.details = updatedOrder.details;
          }

          await this.$swal('Sukses', `${updatedOrder.no} Berhasil Di-update`, 'success');
          this.setLocalStorage();
        }


      } catch (error) {
        this.isLoading = false;
        console.log(error.response);
        this.$swal('Gagal', error.response.data.message, 'error');
      } finally {
        this.isLoading = false;

        this.$bvModal.hide(this.deliveryOrder.modal.id);
        this.reset();
      }
    },
    onInvoiceSelected (items) {
      this.deliveryOrder.selectedInvoices = items;
    },
    async remove (item, index) {
      if (!item) return;

      const no = item.no;

      const url = this.api.main.url;

      const status = await this.$swal({
        title: 'Apakah anda yakin?',
        text: 'Anda akan menhapus Delivery Order',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      });

      if (!status.value) return;
      try {
        this.isLoading = true;
        const response = await this.$axios.delete(`${url}/${item.id}`,);

        if (response.status === 200 || response.status === 201) {

          this.isLoading = false;
          this.table.items.splice(index, 1);
          this.table.items.forEach((item, index) => {
            item.row = index + 1;
          });
          await this.$swal('Sukses', `${no} Berhasil Di-hapus`, 'success');
          this.setLocalStorage();
        }
      } catch (error) {
        this.isLoading = false;
        console.log(error.response);
        this.$swal('Gagal', error.response.data.message, 'error');
      } finally {
        this.isLoading = false;
      }
    },
    async setCustomer (value) {
      if (value === null) return this.reset();
      this.customer.value = value;
      await this.$nextTick();
      this.$refs.selectableTable.selectAllRows();
    },
    reset () {
      this.customer.value = null;
      this.deliveryOrder.edit = false;
      this.deliveryOrder.selectedInvoices = [];
    },
    async edit (item) {
      this.deliveryOrder.edit = true;
      this.deliveryOrder.value = item;
      this.deliveryOrder.modal.title = `Edit #${item.no}`;

      const url = this.getUrl(this.api.customer);
      this.isLoading = true;
      try {
        const response = await this.$axios.get(`${url}&filter[id]=${item.customer.id}`);

        const newCustomer = response.data.data[0];
        console.log(response);

        if (response.status === 200 || response.status === 201) {
          const modifiedCustomer = {
            id: item.customer.id,
            name: item.customer.name,
            salesTransactions: item.details.map(detail => {
              return {
                ...detail.salesTransaction
              };
            })
          };

          this.customer.items = [
            // { id: null, name: 'Harap Pilih Salah Satu' },
            modifiedCustomer
          ];

          this.customer.value = modifiedCustomer;
          this.$bvModal.show(this.deliveryOrder.modal.id);
          const existingLength = this.customer.items[0].salesTransactions.length;

          if (newCustomer) {
            newCustomer.salesTransactions.forEach(newInvoice => {
              this.customer.items[0].salesTransactions.push(newInvoice);
            });
          }

          await this.$nextTick();

          for (let i = 0; i < existingLength; i++) {
            this.$refs.selectableTable.selectRow(i);
          }
        }

      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    print (item) {
      if (item.type === 'Serial') {
        this.deliveryOrder.value = item;
        window.open(`print/${item.id}`);
      } else {
        const url = `http://frog.megatrend.xyz/deliveryorder/vprint/${item.id}`;
        // const url = `https://www.mymegatrend.com/deliveryorder/vprint/${item.id}`;
        window.open(url);
      }
    },
    getCountSerialByDo (item) {
      return item.details.reduce((carry, detail) => {
        if (!detail.salesTransaction.packingSerial) return carry + 0;
        return carry + detail.salesTransaction.packingSerial.details.length;
      }, 0);
    }
  }
}
</script>

<style>
#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  margin-top: 20px;
}
.float-right {
  float: right;
}
.custom-modal-footer {
  /* border-top: 0px; */
  justify-content: center !important;
}
.form-control {
  background-color: white !important;
}
.form-table-print {
  margin-top: 0.8rem;
}

.margin-upper-signature {
  margin-top: 60px;
}
.text-right {
  text-align: right;
}

.btn-outline-primary {
  color: #173b8f !important;
  border-color: #173b8f !important;
}
.btn-outline-primary:hover {
  color: white !important;
  background-color: #173b8f !important;
}

@media print {
  .form-table-print {
    margin-top: 0;
    float: none;
    page-break-after: always !important;
  }

  .no-print,
  .no-print * {
    display: none !important;
  }
  /* .page-break {
    display: block;
    page-break-before: always;
  } */
}
</style>
