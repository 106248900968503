<template>
  <div class="form-table-print">
    <b-container class="text-smaller">
      <b-row
        v-if="type === 'original'"
        align-h="between"
        class="no-print border-bottom border-dark mb-3 pb-3"
      >
        <b-col>
          <b-button variant="outline-primary" @click="close()">Tutup</b-button>
        </b-col>
        <b-col class="text-right">
          <b-button variant="warning" @click="print">Cetak</b-button>
        </b-col>
      </b-row>
      <div class="content-print">
        <b-row>
          <b-col cols="3">
            <div>
              <h3 class="mb-0">MEGATREND</h3>
            </div>
            <div class="font-">PT. Mega Visi Prima Jaya</div>
          </b-col>
          <b-col
            cols="6"
            class="text-center d-flex align-items-end justify-content-center"
          >
            <h3 class="mb-0">
              <u>{{ title }}</u>
            </h3>
          </b-col>
          <b-col cols="3" class="d-flex justify-content-end">
            <div v-if="type === 'original'"></div>
            <span
              v-if="type === 'original'"
              class="stamp-original-box text-center"
            >
              <h3 class="d-inline text-red">ASLI</h3>
            </span>
            <span v-if="type === 'copy'" class="stamp-copy-box text-center">
              <h3 class="d-inline">COPY</h3>
            </span>
          </b-col>
        </b-row>
        <!-- <b-row
        v-if="title!==false"
        class="my-3"
      >
        <b-col class="text-center">
          <span class="no-print float-left">
            <slot name="left-side-title" />
          </span>
          <span class="text-title">
            <h2><u>{{ title }}</u></h2>
          </span>
          <span class="no-print float-right">
            <slot name="right-side-title">
              <b-button
                v-if="button"
                variant="outline-primary"
                size="lg"
                onclick="window.print()"
              >
                Cetak
              </b-button>
            </slot>
          </span>
        </b-col>
      </b-row> -->
        <!-- <hr
        v-if="title!==false"
        class="my-2"
      > -->
        <b-row class="my-3">
          <b-col cols="4">
            <b-row>
              <b-col cols="3">{{ headers.no.label }}</b-col>
              <b-col cols="1">:</b-col>
              <b-col cols="7">{{
                deliveryOrder ? deliveryOrder.no : ""
              }}</b-col>
            </b-row>
            <b-row>
              <b-col cols="3">{{ headers.date.label }}</b-col>
              <b-col cols="1">:</b-col>
              <b-col cols="7">{{
                deliveryOrder ? deliveryOrder.shipmentDate : ""
              }}</b-col>
            </b-row>
            <b-row>
              <b-col cols="3">{{ headers.type.label }}</b-col>
              <b-col cols="1">:</b-col>
              <b-col cols="7">{{
                deliveryOrder ? deliveryOrder.type : ""
              }}</b-col>
            </b-row>
          </b-col>
          <b-col cols="4">
            <b-row>
              <b-col cols="3">{{ headers.customer.label }}</b-col>
              <b-col cols="1">:</b-col>
              <b-col cols="7"
                ><b>{{
                  deliveryOrder ? deliveryOrder.customer.name : ""
                }}</b></b-col
              >
            </b-row>
          </b-col>
          <b-col cols="4">
            <b-row>
              <b-col cols="3">{{ headers.address.label }}</b-col>
              <b-col cols="1">:</b-col>
            </b-row>
            <b-row>
              <b-col>
                {{ deliveryOrder ? deliveryOrder.customer.address : "" }}</b-col
              >
            </b-row>
          </b-col>
        </b-row>
        <!-- <hr
        v-if="headers.length >0"
        class="my-4"
      > -->
        <!-- <slot name="table">
        <b-row>
          <b-col>
            <b-table
              class="text-center"
              fixed
              bordered
              :items="items"
              :fields="fields"
            >
              <template #cell(index)="row">
                {{ row.index +1 }}
              </template>
              <template
                v-for="slotName in Object.keys($scopedSlots)"
                #[slotName]="slotScope"
              >
                <slot
                  :name="slotName"
                  v-bind="slotScope"
                />
              </template>
            </b-table>
          </b-col>
        </b-row>
      </slot> -->
        <!-- <slot name="footer" /> -->
        <b-table-simple small responsive bordered class="text-center report">
          <b-thead class="border-1 border-dark">
            <b-tr class="border-1 border-dark">
              <b-th
                class="border-1 border-dark"
                v-for="field in fields"
                :key="field.key"
                >{{ field.label }}</b-th
              >
            </b-tr>
          </b-thead>
          <b-tbody class="border-1 border-dark">
            <b-tr v-for="(item, index) in items" :key="item.product.id">
              <b-th
                v-if="
                  index === 0 ||
                  items[index - 1].salesTransactionId !==
                    item.salesTransactionId
                "
                :rowspan="getLengthDetails(item.salesTransactionId)"
                >{{ item.row }}</b-th
              >
              <b-th
                v-if="
                  index === 0 ||
                  items[index - 1].salesTransactionId !==
                    item.salesTransactionId
                "
                :rowspan="getLengthDetails(item.salesTransactionId)"
                >{{ item.salesTransactionNo }}</b-th
              >
              <b-td>{{ index + 1 }}</b-td>
              <b-td>{{ item.product.code }}</b-td>
              <b-td>{{ item.product.name }}</b-td>
              <b-td
                >{{ item.unitQty | accounting }} {{ item.unit.code }} ({{
                  item.qty
                }}
                {{ item.product.baseUnit.code }})</b-td
              >
            </b-tr>
          </b-tbody>
          <!-- <b-tfoot>
            <b-tr>
              <b-td
                colspan="5"
                variant="secondary"
                class="text-right"
              >
                Total Rows: <b>5</b>
              </b-td>
            </b-tr>
          </b-tfoot> -->
        </b-table-simple>
      </div>
      <div class="footer-signature">
        <b-row align-h="around" class="div-signature">
          <b-col class="text-center">
            <div>Diserahkan Oleh :</div>
            <div class="margin-upper-signature">
              (............................)
            </div>
          </b-col>
          <b-col class="text-center">
            <div>Diterima Oleh :</div>
            <div class="margin-upper-signature">
              (............................)
            </div>
          </b-col>
          <b-col class="text-center d-flex">
            <div class="count-box align-self-center">
              <div class="border-dark border-top border-left border-right">
                Total Dus
              </div>
              <div class="border-dark count-box border py-3">
                {{ totalSerials }}
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
// import VueBarcode from "vue-barcode";
export default {
  name: "FormTablePrinting",
  // components: {
  //   barcode: VueBarcode,
  // },
  props: {
    title: {
      type: [Boolean, String],
      default: "Delivery Order",
    },
    button: {
      type: Boolean,
      default: true,
    },
    // deliveryOrder: {
    //   type: Object,
    //   required: true
    // },
    type: {
      type: String,
      default: "original",
    },
    // headers: {
    //   type: Object,
    //   default: () => {
    //     return {};
    //   }
    // },
    // items: {
    //   type: Array,
    //   default: () => []
    // },
    fields: {
      type: Array,
      default: () => [
        { key: "index", label: "No." },
        { key: "salesTransaction", label: "No. Invoice" },
        { key: "productIndex", label: "#" },
        { key: "productCode", label: "Kode" },
        { key: "productName", label: "Nama" },
        { key: "qty", label: "Qty" },
      ],
    },
  },
  data() {
    return {
      deliveryOrder: null,
      headers: {
        no: { label: "Nomor", value: "DO-192381321" },
        customer: { label: "Kepada", value: "Junior Online Store" },
        address: { label: "Alamat", value: "Jl. Merdeka" },
        date: { label: "Tanggal", value: "12/05/2021" },
        type: { label: "Type", value: "Serial" },
      },
    };
  },
  computed: {
    totalSerials() {
      if (!this.deliveryOrder) return [];

      const total = this.deliveryOrder.details.reduce((carry, detail) => {
        return carry + detail.salesTransaction.packingSerial.details.length;
      }, 0);

      return total;
    },
    items() {
      if (!this.deliveryOrder) return [];

      const items = this.deliveryOrder.details.reduce(
        (carry, detail, index) => {
          const details = detail.salesTransaction.details.map((stDetail) => {
            return {
              ...stDetail,
              salesTransactionId: detail.salesTransaction.id,
              salesTransactionNo: detail.salesTransaction.no,
              customer: detail.salesTransaction.customer,
              row: index + 1,
            };
          });

          carry = [...carry, ...details];

          return carry;
        },
        []
      );

      return items;
    },
  },
  mounted() {
    const items = JSON.parse(window.localStorage.getItem("do"));

    const item = items.find(
      (item) => item.id === parseInt(this.$route.params.id)
    );

    this.deliveryOrder = item;

    console.log(item);
  },
  methods: {
    print() {
      window.print();
    },
    close() {
      window.close();
    },
    getLengthDetails(salesTransactionId) {
      const filtered = this.items.filter(
        (item) => item.salesTransactionId === salesTransactionId
      );

      return filtered.length;
    },
    getCols(cols, type) {
      const defaultCols = 12;

      if (!cols) {
        return defaultCols;
      }

      const xs = cols ? (cols.xs ? cols.xs : defaultCols) : defaultCols;

      switch (type) {
        case "xs":
          return xs;

        case "sm":
          return cols.sm ? cols.sm : xs;

        case "md":
          return cols.md ? cols.md : xs;

        case "lg":
          return cols.lg ? cols.lg : xs;

        case "xl":
          return cols.xl ? cols.xl : xs;

        default:
          return xs;
      }
    },
  },
};
</script>
<style>
.stamp-original-box {
  border: 3px solid red;
  width: 120px;
  height: 45px;
  line-height: 50px;
}
.stamp-copy-box {
  border: 3px solid black;
  width: 120px;
  height: 45px;
  line-height: 50px;
}

.text-red {
  color: red;
}
.label-header {
  width: 100px;
}
.text-smaller {
  font-size: 0.8em;
}

.count-box {
  width: 200px;
}
.border-right {
  border-right: 1px solid;
}
.border-left {
  border-left: 1px solid;
}

@media print {
  /* .report {
    page-break-inside: auto;
    page-break-after: auto;
  }
  .report tr {
    page-break-inside: avoid;
    page-break-after: auto;
  } */
  /* .report td {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  .report thead {
    display: table-header-group;
  }
  .report tfoot {
    display: table-footer-group;
  } */

  /* .report {
    max-height: 521px;
    page-break-inside: auto;
    page-break-after: auto;
  }

  .report tr {
    page-break-inside: avoid;
    page-break-after: auto;
  } */

  .footer-signature {
    page-break-inside: avoid;
    page-break-after: auto;
    /* position: fixed;
  bottom: 0;
    height: 100px;
    width: 95%;
    margin: 0 auto; */
  }
}
</style>
