<template>
  <div>
    <b-row
      align-v="center"
      class="my-4"
    >
      <b-col
        md="5"
        lg="6"
      >
        <slot name="searchBar">
          <b-row>
            <b-col class="my-1">
              <b-form-input
                v-model="localFilter"
                type="search"
                placeholder="Cari..."
              />
            </b-col>
          </b-row>
        </slot>
      </b-col>
      <b-col
        md="7"
        lg="6"
      >
        <app-pagination-stack
          :per-page="perPage"
          :page-options="pageOptions"
          :total-rows="totalRows"
          :current-page="currentPage"
          @per-page-changed="row => $emit('per-page-changed', row)"
          @page-changed="page => $emit('page-changed', page)"
        />
      </b-col>
    </b-row>
    <div>
      <b-table
        :fields="fields"
        :items="items"
        :filter="localFilter"
        sticky-header="80vh"
        responsive
        bordered
        hover
        class="text-center"
        @row-dblclicked="(row,index)=>$emit('table-row-dbl-clicked',row,index)"
      >
        <template
          v-for="slotName in Object.keys($scopedSlots)"
          #[slotName]="slotScope"
        >
          <slot
            :name="slotName"
            v-bind="slotScope"
          />
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import AppPaginationStack from './PaginationStack.vue';
export default {
  name: 'PaginationTable',
  components: {
    AppPaginationStack
  },
  props: {
    fields: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    perPage: {
      type: Number,
      default: 25
    },
    pageOptions: {
      type: Array,
      default: () => [
        25,
        50,
        100
      ]
    },
    totalRows: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    }
    // table: {
    //   type: Object,
    //   default: () => {
    //     return {
    //       fields: [],
    //       items: [],
    //       pagination: {
    //         perPage: 25,
    //         pageOptions: [
    //           25,
    //           50,
    //           100
    //         ],
    //         totalRows: 0,
    //         currentPage: 1
    //       }
    //     };
    //   }
    // }
  },
  data () {
    return {
      localFilter: ''
    };
  }

};
</script>

<style scoped>
.table-wrapper-custom {
  height: 800px;
}
</style>
