export default function useRequest () {
  const isObject = (value) => {
    if (value == null ||
      typeof value !== 'object' ||
      Array.isArray(value)
    ) {
      return false;
    }

    return true;
  };


  /**
   * @param {Object} config - The set of Url and Query
   * @param {string} config.url - The url address
   * @param {Object} config.query - The query config
   * @param {string} config.query.sort - The value of sort
   * @param {string[]} config.query.includes - An array of include keys
   * @param {string[]} config.query.appends - An array of append keys
   * @param {Object[]} config.query.fields - An array of field config
   * @param {string} config.query.fields[].name - The name of key attribute
   * @param {string[]} config.query.fields[].columns - An array of column name of key attribute
   * @param {Object[]} config.query.filters - An array of filter config
   * @param {string} config.query.filters[].name - The name of filter
   * @param {string} config.query.filters[].value - The value of filter
   * @param {string[]} config.query.others - An array of other query strings to be added
   */
  function getUrl ({ url, query } = {}) {
    // if (typeof config !== 'object'
    //   || Array.isArray(config)
    //   || config == null
    //   || !this.$_g_has(config, 'url')
    // ) {
    //   return null;
    // }

    // if (customUrl !== undefined && customUrl !== null && customUrl !== '') url = customUrl;
    // let url = customUrl ? customUrl : config.url;

    if (url === undefined || url === null || url === '') { return; }

    url = url.concat('?');

    if (query !== undefined && query !== null) {
      url = url.concat(getQuery(query));
    }

    return url;
  }
  /**
   * @param {Object} query - The query config
   * @param {string} query.sort - The value of sort
   * @param {string[]} query.includes - An array of include keys
   * @param {string[]} query.appends - An array of append keys
   * @param {Object[]} query.fields - An array of field config
   * @param {string} query.fields[].name - The name of key attribute
   * @param {string[]} query.fields[].columns - An array of column name of key attribute
   * @param {Object[]} query.filters - An array of filter config
   * @param {string} query.filters[].name - The name of filter
   * @param {string} query.filters[].value - The value of filter
   * @param {string[]} query.others - An array of other query strings to be added
   */
  function getQuery ({ sort, includes, appends, fields, filters, others } = {}) {
    let queryString = '';

    // if (typeof query !== 'object'
    //   && Array.isArray(query)
    // ) {
    //   return queryString;
    // }

    if (sort !== null && sort !== undefined) {
      queryString = queryString.concat(`&sort=${sort}`);
    }

    if (includes !== null && includes !== undefined && includes.length > 0) {
      queryString = queryString.concat(`&include=${includes.toString()}`);
    }

    if (appends !== null && appends !== undefined && appends.length > 0) {
      queryString = queryString.concat(`&append=${appends.toString()}`);
    }

    if (fields !== null && fields !== undefined && fields.length > 0) {
      fields.forEach((field) => {
        if (field.name && field.columns.length > 0) {
          queryString = queryString.concat(
            `&field[${field.name}]=${field.columns.toString()}`
          );
        }
      });
    }

    if (filters !== null && filters !== undefined && filters.length > 0) {
      // query.filters.forEach(filter => {
      //   if (filter.name && filter.value != null && filter.value !== '') {
      //     queryString = queryString.concat(
      //       `&filter[${filter.name}]=${filter.value}`
      //     );
      //   }
      // });

      queryString = queryString.concat(
        getQueryFilters({ filters })
      );
    }

    if (others !== null && others !== undefined && others.length > 0) {
      others.forEach((other) => {
        queryString = queryString.concat(`&${other}`);
      });
    }

    return queryString;
  }
  function getQueryFilters ({ filters } = {}) {
    let queryString = '';

    if (!Array.isArray(filters) ||
      filters.length === 0
    ) {
      return queryString;
    }

    filters.forEach((filter) => {
      let value = filter.queryValue || filter.value;

      if (isObject(value)) {
        let key = 'id';

        if (filter.key) {
          key = filter.key;
        }

        value = value[key];
      }

      if (filter.queryHidden !== true && value !== undefined && value !== null && value !== '') {
        const queryName = filter.queryName || filter.name;

        if (filter.name === 'branch') {
          const warehouseFilter = filters.find(el => el.name === 'warehouse');

          if (!warehouseFilter.value || !warehouseFilter.value.id) { queryString = queryString.concat(`&filter[${queryName}]=${value}`); }
        } else {
          queryString = queryString.concat(`&filter[${queryName}]=${value}`);
        }
      }
    });

    return queryString;
  }

  return {
    getUrl,
    getQuery,
    getQueryFilters
  };
}
