<template>
  <b-row
    align-h="end"
    class="mr-1"
  >
    <b-col
      cols="6"
      md="4"
      class="my-2 text-center pt-1"
    >
      Total : {{ totalRows | accounting}} Records
    </b-col>
    <b-col
      cols="6"
      md="3"
      class="px-0 my-2 text-center pt-1"
    >
      <span>Show</span>
      <select
        id="perPageSelect"
        name="show"
        :value="perPage"
        @change="event => $emit('per-page-changed', parseInt(event.target.value))"
      >
        <option
          v-for="(option, index) in pageOptions"
          :key="index"
          :value="option"
        >
          {{ option }}
        </option>
      </select>
    </b-col>
    <b-col
      cols="12"
      md="5"
      class="my-1"
    >
      <b-pagination
        limit="3"
        :hide-ellipsis="true"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        :value="currentPage"
        @change="page => $emit('page-changed', page)"
      />
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'PaginationStack',
  props: {
    perPage: {
      type: Number,
      default: 25
    },
    pageOptions: {
      type: Array,
      default: () => {
        return [
          25,
          50,
          100
        ];
      }
    },
    totalRows: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    }
  }
};
</script>

<style>
ul {
  margin: auto !important;
}
</style>
